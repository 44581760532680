// Action Types
export const Types = {
    LIST_ROLES: 'LIST_ROLES',
    LIST_ROLES_SUCCESS: 'LIST_ROLES_SUCCESS',
    CREATE_ROLE: 'CREATE_ROLE',
    UPDATE_ROLE: 'UPDATE_ROLE',
    DELETE_ROLE: 'DELETE_ROLE'
}

// Reducer
const INITIAL_STATE = {
    roles: []
}

export default function reducer(state = INITIAL_STATE, action) {
    if(action.type === Types.LIST_ROLES_SUCCESS){
        return {
            ...state,
            roles: action.payload
        }
    }else{
        return state;
    }
}

// Action Creators
export const listRoles = () => {
    return {
        type: Types.LIST_ROLES
    }
}

export const listRolesSuccess = (roles) => {
    return {
        type: Types.LIST_ROLES_SUCCESS,
        payload: roles
    }
}

export const createRole = (role) => {
    return {
        type: Types.CREATE_ROLE,
        payload: role
    }
}

export const updateRole = (role) => {
    return {
        type: Types.UPDATE_ROLE,
        payload: role
    }
}

export const deleteRole = (id) => {
    return {
        type: Types.DELETE_ROLE,
        payload: id
    }
}
