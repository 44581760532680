import React from "react";
import {Button, Form, Input, message, Layout} from "antd";
import {connect} from "react-redux";
import image584 from "../../assets/images/bg_signin.gif"


import {
  hideMessage,
  userSignIn,
  showLoader
} from "../../appRedux/reducers/Auth.js";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;
const {Footer} = Layout;

class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showLoader(true);
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser.companyId !== undefined) {
      this.props.history.push('/');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage} = this.props;

    return (
      
        <div className="gx-app-login-wrap">
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content">
                <div className="gx-app-logo-content-bg">
                  <img src={image584} alt='Neature'/>
                </div>
                <div className="gx-app-logo-wid">
                  <h1><IntlMessages id="app.userAuth.signIn"/></h1>
                  <p><IntlMessages id="app.userAuth.bySigning"/></p>
                  <p><IntlMessages id="app.userAuth.getAccount"/></p>
                </div>
                <div className="gx-app-logo">
                  <img alt="example" src={require("assets/images/logo.png")}/>
                </div>
              </div>
              <div className="gx-app-login-content">
                <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0" id="loginForm">

                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{required: true, type: 'email', message: 'Preciso de um email válido'}]
                  })(
                    <Input placeholder="Email"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Por favor digite a senha!'}],
                  })(
                    <Input type="password" placeholder="Password"/>
                  )}
                </FormItem>
                <FormItem>
                  <Button id="blue-button" type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                </FormItem>
              </Form>
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>

        </div>
            <div>
              <Footer>
              <div className="gx-layout-footer-content">
                Solutis Tecnologias © 2019
              </div>
              </Footer>
            </div>
      </div>
    
        
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showLoader
})(WrappedNormalLoginForm);
