import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {onNavStyleChange, toggleCollapsedSideNav} from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";


class SidebarLogo extends Component {

  render() {
    const {width, themeType, navCollapsed} = this.props;
    let {navStyle} = this.props;
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navStyle = NAV_STYLE_DRAWER;
    }
    return (
      <div className="gx-layout-sider-header">

        {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">

          <i id="sandwichButton"
            className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
            onClick={() => {
              switch (navStyle) {
                case NAV_STYLE_DRAWER:
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                  break;
                case NAV_STYLE_FIXED:
                  this.props.onNavStyleChange(NAV_STYLE_MINI_SIDEBAR)
                  break;
                default:
                  this.props.onNavStyleChange(NAV_STYLE_FIXED)
                  break;
              }
            }}
          />
        </div> : null}

        <Link to="/" className="gx-site-logo">
          {width >= TAB_SIZE ?
            <img alt="" src={require("assets/images/logo-white.png")}/> :
            themeType === THEME_TYPE_LITE ?
              <img alt="" src={require("assets/images/logo-white.png")}/> :
              <img alt="" src={require("assets/images/logo.png")}/>}

        </Link>

      </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, width, navCollapsed} = settings;
  return {navStyle, themeType, width, navCollapsed}
};

export default connect(mapStateToProps, {
  onNavStyleChange,
  toggleCollapsedSideNav
})(SidebarLogo);
