// Action Types
export const Types = {
    LIST: 'LIST',
    LIST_SUCCESS: 'LIST_SUCCESS',
    REMOVE: 'REMOVE'
}

// Reducer
const INITIAL_STATE = {
    files:[]
}

export default function reducer(state = INITIAL_STATE, action) {
    if(action.type === Types.LIST_SUCCESS ){
        return {
            ...state,
            files: action.payload
        }
    }else{
        return state
    }
}

// Action Creators
export function list(id) {
    return {
        type: Types.LIST,
        payload: id
    }
}

export function remove(name) {
    return {
        type: Types.REMOVE,
        payload: name
    }
}

export function listSuccess(files) {
    return {
        type: Types.LIST_SUCCESS,
        payload: files
    }
}
