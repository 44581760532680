import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Conversation {

	static countAllByCompanyId(data) {
		const { filters } = data

		return new Promise((resolve, reject)=>{
			return Api.post(`/conversation/count`, filters).then(resultCount =>  {
				if(resultCount.length > 0 && resultCount[0].hasOwnProperty("total")) {
					resolve(resultCount[0].total)
				}else{
					resolve(0)
				}
			}).catch((err) => {
				reject(handleError(err,"Ocorreu um erro ao calcular o total de entendimento"))
			})
		})
	}

	static findTopIntentsByCompanyId(data) {
		const {filters} = data
		return new Promise((resolve, reject)=>{
			return Api.post(`/conversation/intents`, filters).then(resultCount =>  {
				resolve(resultCount)
			}).catch((err) => {
				reject(handleError(err,"Ocorreu um erro ao pesquisar as intenções mais identificadas"))
			})
		})
	}

	static findTopEntitiesByCompanyId(data) {
		const {filters} = data
		return new Promise((resolve, reject)=>{
			return Api.post(`/conversation/entities`, filters).then(resultCount =>  {
				resolve(resultCount)
			}).catch((err) => {
				reject(handleError(err,"Ocorreu um erro ao pesquisar as entidades mais identificadas"))
			})
		})
	}

	static findMessages(data) {
		const {filters, companyId} = data
		return new Promise((resolve, reject)=>{
			return Api.post(`/conversation/all/${companyId}`, filters).then(messages =>  {
				resolve(messages)
			}).catch((err) => {
				reject(handleError(err,"Ocorreu um erro ao pesquisar as mensagens"))
			})
		})
	}

	static findConversationsInProgressByCompanyId(filters) {
		return new Promise((resolve, reject)=>{
			return Api.post(`/conversation/inprogress`, filters).then(resultCount =>  {
				resolve(resultCount)
			}).catch((err) => {
				reject(err)
			})
		})
	}

	static findMessagesById(conversationId) {
		return new Promise((resolve, reject)=>{
			return Api.get(`/conversation/messages/${conversationId}`).then(messages => {
				resolve(messages)
			}).catch((err) => {
				reject(handleError(err,"Ocorreu um erro ao pesquisar as mensagens"))
			})
		})
	}

	static startIntervention(message) {
		return new Promise((resolve, reject)=>{
			return Api.post(`/conversation/intervention`, message).then(data => {
				resolve(data)
			}).catch((err) => {
				reject(handleError(err,"Ocorreu um erro ao iniciar a intervenção."))
			})
		})
	}
}

export default Conversation
