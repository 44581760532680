import {
    CHANNEL_CREATE,
    CHANNEL_CREATE_SUCCESS,
    CHANNEL_CREATE_ERROR,

    CHANNEL_UPDATE,
    CHANNEL_UPDATE_SUCCESS,
    CHANNEL_UPDATE_ERROR,

    CHANNEL_GET_ALL,
    CHANNEL_GET_ALL_SUCCESS,
    CHANNEL_GET_ALL_ERROR,

    CHANNEL_DELETE,
    CHANNEL_DELETE_SUCCESS,
    CHANNEL_DELETE_ERROR,

    CHANNEL_DETAIL,
    CHANNEL_DETAIL_SHOW,
    CHANNEL_DETAIL_HIDE,

    CHANNEL_COMPONENT_VISIBLE,
    CHANNEL_MODAL_VISIBLE
} from "../../constants/ActionTypes";

export const channelCreate = (data, company, channel) => {
  return {
      type: CHANNEL_CREATE,
      payload: {
        data: data,
        company: company,
        channel: channel
      }
  }
}

export const channelCreateSuccess = () => {
  return {
      type: CHANNEL_CREATE_SUCCESS
  }
}

export const channelCreateError = () => {
  return {
      type: CHANNEL_CREATE_ERROR
  }
}

export const channelUpdate = (data, company, channel) => {
  return {
      type: CHANNEL_UPDATE,
      payload: {
        data: data,
        company: company,
        channel: channel
      }
  }
}

export const channelUpdateSuccess = (data) => {
  return {
      type: CHANNEL_UPDATE_SUCCESS,
      payload: data
  }
}

export const channelUpdateError = () => {
  return {
      type: CHANNEL_UPDATE_ERROR
  }
}

export const channelGetAll = (id) => {
  return {
      type: CHANNEL_GET_ALL,
      payload: id
  }
}

export const channelGetAllSuccess = (channel) => {
  return {
      type: CHANNEL_GET_ALL_SUCCESS,
      payload: channel
  }
}

export const channelGetAllError = () => {
  return {
      type: CHANNEL_GET_ALL_ERROR
  }
}

export const channelDelete = (channel) => {
  return {
      type: CHANNEL_DELETE,
      channel: channel
  }
}

export const channelDeleteSuccess = (channel) => {
  return {
      type: CHANNEL_DELETE_SUCCESS,
      payload: channel
  }
}

export const channelDetailShow = (channel) => {

  return {
      type: CHANNEL_DETAIL_SHOW,
      payload: channel
  }
}

export const channelDetailHide = (channel) => {
  return {
      type: CHANNEL_DETAIL_HIDE,
      payload: channel
  }
}

export const channelDeleteError = () => {
  return {
      type: CHANNEL_DELETE_ERROR
  }
}

export const channelDetail = (channel) => {
  return {
      type: CHANNEL_DETAIL,
      payload: channel
  }
}

export const channelModalVisible = (visible, create) => {
  return {
      type: CHANNEL_MODAL_VISIBLE,
      payload: {
          visible,
          create
      }
  };
};

export const channelComponentVisible = (loading) => {
  return {
      type: CHANNEL_COMPONENT_VISIBLE,
      payload: loading
  };
};
