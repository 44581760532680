import React, { Component } from "react";
import { connect } from "react-redux";
import { Progress } from "antd";
import { Link } from "react-router-dom";

class NotificationItem extends Component {
  render() {
    const { step } = this.props
    return (
      <div>
        {(step < 4) &&
          <li className="gx-media">
            <Link to="/orquestra">
              <Progress width={100} percent={(step * 25)} format={percent => `${(4 * percent) / 100}/4`} />
              <div className="gx-media-body gx-align-self-center">
                <p className="gx-fs-sm gx-mb-0">Finalize o tutorial de criação da orquestra</p>
              </div>
            </Link>
          </li>
        }
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    step: store.tutorial.step
  }
}

export default connect(mapStateToProps)(NotificationItem);
