import {
    ERROR_MESSAGE,
    SUCCESS_MESSAGE,
    INFO_MESSAGE,
    WARNING_MESSAGE
} from "../../constants/ActionTypes";

export const errorMessage = (msg) => {
    return {
        type: ERROR_MESSAGE,
        payload: msg
    };
};

export const successMessage = (msg) => {
    return {
        type: SUCCESS_MESSAGE,
        payload: msg
    };
};

export const infoMessage = (msg) => {
    return {
        type: INFO_MESSAGE,
        payload: msg
    };
};

export const warningMessage = (msg) => {
    return {
        type: WARNING_MESSAGE,
        payload: msg
    }
}
