import { INTEGRATION_MODAL_VISIBLE } from "constants/ActionTypes";

const INIT_STATE = {
	visible: false
}

export default (state = INIT_STATE, action) => {
	if (action.type === INTEGRATION_MODAL_VISIBLE) {
		return {
			...state,
			visible: action.payload
		}
	} else {
		return state
	}
}
