import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import UserService from "../../services/User";

import { Types,
    listUserSuccess
} from "../reducers/User"

import { errorMessage } from "../actions/FeedbackMessage"

import { componentLoading, buttonLoading, toggleModal } from "../actions/loading";

const listRequest = async () => UserService.list()
    .then(users => users)
    .catch(error => { throw error });

const createRequest = async (data) => UserService.create(data)
    .then(user => user)
    .catch(error => { throw error });

const removeRequest = async (id) => UserService.remove(id)
    .then(removedUser => removedUser)
    .catch(error => { throw error });

const updateRequest = async (data) => UserService.update(data)
    .then(updatedUser => updatedUser)
    .catch(error => { throw error });

function* list() {
    try {
        yield put(componentLoading(true))

        const users = yield call(listRequest)

        yield put(componentLoading(false))
        yield put(listUserSuccess(users))
    } catch (error) {
        yield put(componentLoading(false))
        yield put(errorMessage(error))
    }
}

function* create({payload}) {
    try {
        yield put(buttonLoading(true))

        yield call(createRequest, payload)
        yield put(buttonLoading(false))
        yield put(toggleModal(false))

        const users = yield call(listRequest)
        yield put(listUserSuccess(users))

    } catch (error) {
        yield put(buttonLoading(false))
        yield put(toggleModal(false))

        yield put(errorMessage(error))
    }
}

function* remove({payload}) {
    try {
        yield put(buttonLoading(true))

        yield call(removeRequest, payload)
        yield put(buttonLoading(false))

        const users = yield call(listRequest)
        yield put(listUserSuccess(users))
    } catch (error) {
        yield put(errorMessage(error))
    }
}

function* update({payload}) {
    try {
        yield put(buttonLoading(true))

        yield call(updateRequest, payload)
        yield put(buttonLoading(false))
        yield put(toggleModal(false))

        const users = yield call(listRequest)
        yield put(listUserSuccess(users))
    } catch (error) {
        yield put(buttonLoading(false))
        yield put(toggleModal(false))

        yield put(errorMessage(error))
    }
}

export function* removeSaga(){
    yield takeLatest(Types.REMOVE_USER, remove)
}

export function* listSaga() {
    yield takeLatest(Types.LIST_USER, list);
}

export function* createSaga() {
    yield takeLatest(Types.CREATE_USER, create);
}

export function* updateSaga() {
    yield takeLatest(Types.UPDATE_USER, update);
}

export default function* rootSaga() {
    yield all([
        fork(listSaga),
        fork(createSaga),
        fork(updateSaga),
        fork(removeSaga),
    ]);
}
