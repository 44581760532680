const build = (message, currentConversation, user) => {

    const context =  {...currentConversation.context}
    context.userId = currentConversation.socketId
    context.orcSlice.flow.intervention = true
    return {
        status:"INT",
        context : {
            ...context,
            from:"analist"
        },
        messages: [
            {
                responseType : "text",
                text : message
            }
        ],
        conversationId : currentConversation._id,
        attendantName: user.name
    }
}

const extractMessages = conversation => {
    let from = "user"
    if(conversation.context){
        from = conversation.context.from
    }

    return {
        conversationId: conversation.conversationId,
        from,
        messages: conversation.messages
    }
}

export { build, extractMessages }
