import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Company {

	static list() {
		return new Promise((resolve, reject)=>{
			return Api.get('/companies').then(companies =>  {
				resolve(companies)
			}).catch((err) => {
				reject(handleError(err))
			})
		})
	} 

	static listById(id) {
		return new Promise((resolve, reject)=>{
			return Api.get(`/companies/${id}`).then(company =>  {
				resolve(company)
			}).catch((err) => {
				reject(handleError(err))
			})
		})
	}

	static create(company) {
		return new Promise((resolve, reject)=>{
			return Api.post('/companies', company).then(company =>  {
				resolve(company)
			}).catch((err) => {
				reject(handleError(err, "Ocorreu um erro ao criar a empresa"))
			})
		})
	}

	static update(company) {
		return new Promise((resolve, reject)=>{
			return Api.put(`/companies/${company._id}`, company).then(company =>  {
				resolve(company)
			}).catch((err) => {
				reject(handleError(err, "Ocorreu um erro ao atualizar a empresa"))
			})
		})
	}

	static remove(id) {
		return new Promise((resolve, reject)=>{
			return Api.delete(`/companies/${id}`).then(company =>  {
				resolve(company)
			}).catch((err) => {
				reject(handleError(err, "Ocorreu um erro ao deletar a empresa"))
			})
		})
	}
	
	static wordCloudByCompany() {
		return new Promise((resolve, reject)=>{
			return fetch('https://cloud-word.chatbot.solutis.com.br/word/sec')
            .then(response => response.blob())
            .then(blob => {
				 resolve(  URL.createObjectURL(blob) )
            })
            .catch((err) => {
				reject(handleError(err))
			})
		})
	}
}
export default Company
