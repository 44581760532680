import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Feedback {
    static listAll(data) {
        return new Promise((resolve, reject) => {
            return Api.post(`/feedback/all/`,data.filters).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }
}
export default Feedback
