import React from 'react';
import {Redirect, Route} from "react-router-dom";

import {IsAuthorized} from "../../util/AuthUtil"

const RestrictedRoute = ({component: Component, resource, user, ...rest}) => {
  return (
    <Route {...rest} render={props => IsAuthorized(resource, user.permissions) ?
      <Component {...props} /> :
      <Redirect to={{pathname:'/dashboard', state:{from: props.location}}}/>
    }/>
  )
}

export default RestrictedRoute
