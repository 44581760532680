import React, {Component} from "react";
import { bindActionCreators } from 'redux'
import {Layout, Popover} from "antd";
import {Link} from "react-router-dom";
import languageData from "./languageData";
import {switchLanguage, toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import Auxiliary from "util/Auxiliary";
import BugIcon from "../../components/CustomIcons/BugIcon"
import { listenErros, socket } from '../../services/Socket';
import {setOrchestrationError} from "../../appRedux/reducers/Events";

import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {PAGE_LOGS} from "../../constants/ActionTypes";
import {connect} from "react-redux";

const {Header} = Layout;

class Topbar extends Component {

  componentWillUnmount() {
    socket.removeAllListeners()
}

  componentDidMount() {
    listenErros(this.props.setOrchestrationError)
}

  languageMenu = () => (
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            this.props.switchLanguage(language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>

          </li>
        )}
      </ul>
    );

  render() {
    const {locale, width, navCollapsed, navStyle, authUser, errors} = this.props;
    return (
      <Auxiliary>
        <Header>
          {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
            <div className="gx-linebar gx-mr-3">
              <i id="menuIcon" className="gx-icon-btn icon icon-menu"
                 onClick={() => {
                   this.props.toggleCollapsedSideNav(!navCollapsed);
                 }}
              />
            </div> : null}
          <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
            <img alt="" src={require("assets/images/logo-white.png")}/></Link>
          <ul className="gx-header-notifications gx-ml-auto">
              <Auxiliary>
                <li className="gx-notify">
                  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                           trigger="click">
                    <span className="gx-pointer gx-status-pos gx-d-block">
                      <i className="icon icon-notification"/>
                      {(!this.props.step === 0 && this.props.step < 4) && <span className="gx-status gx-status-rtl gx-small gx-orange"/>}
                    </span>
                  </Popover>
                </li>
                {(this.props.step > 3 && authUser.permissions.find(perms => perms.resource === PAGE_LOGS)) && <li className="gx-msg">
                    <Link to="/logs">
                    <span className="gx-pointer gx-status-pos gx-d-block">
                        {errors && <span className="gx-status gx-status-rtl gx-small gx-orange"/>}
                        <BugIcon id="bugIcon"/>
                    </span>
                    </Link>
                  </li>}
              </Auxiliary>
            <li className="gx-language">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={this.languageMenu()}
                       trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${locale.icon}`}/>
                  <i className="icon icon-chevron-down gx-pl-2"/>
                </span>
              </Popover>
            </li>
              <Auxiliary>
                <li className="gx-user-nav"><UserInfo/></li>
              </Auxiliary>
          </ul>
        </Header>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({settings, tutorial, auth, events}) => {
  const {locale, navStyle, navCollapsed, width} = settings;
  const step = tutorial.step
  const {authUser} = auth
  const {errors} = events
  return {locale, navStyle, navCollapsed, width, step, authUser, errors}
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({
        setOrchestrationError,
        toggleCollapsedSideNav,
        switchLanguage
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
