import {
  CONVERSATION_TOTAL_MESSAGES_SUCCESS,
  CONVERSATION_TOTAL_UNDERSTAND_SUCCESS,
  CONVERSATION_TOP_INTENTS_SUCCESS,
  CONVERSATION_TOP_ENTITIES_SUCCESS,
  CONVERSATION_IN_PROGRESS_SUCCESS,
  CONVERSATION_MESSAGES_SUCCESS,
  CONVERSATION_IN_PROGRESS_REMOVE_FROM_LIST,
  CONVERSATION_IN_PROGRESS_UPDATE
} from "../../constants/ActionTypes";

const INIT_STATE = {
  messages:[],
  conversationsInProgress:[],
  totalMessages:{
    totalMessages:0,
    totalSendedMessages:0,
    totalReceivedMessages:0,
  },
  totalUnderstand:{
    totalMessagesFromBot:0,
    totalNotUnderstandMessages:0,
    totalUnderstandMessages:0,
  },
  topIntents:[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONVERSATION_IN_PROGRESS_SUCCESS: {
      return {
        ...state,
        conversationsInProgress : action.payload
      }
    }case CONVERSATION_TOTAL_MESSAGES_SUCCESS: {
      return {
        ...state,
        totalMessages : action.payload
      }
    }case CONVERSATION_TOTAL_UNDERSTAND_SUCCESS: {
      return {
        ...state,
        totalUnderstand : action.payload
      }
    }case CONVERSATION_TOP_INTENTS_SUCCESS: {
      return {
        ...state,
        topIntents : action.payload
      }
    }case CONVERSATION_TOP_ENTITIES_SUCCESS: {
      return {
        ...state,
        topEntities : action.payload
      }
    }case CONVERSATION_MESSAGES_SUCCESS: {
      return {
        ...state,
        messages : action.payload
      }
    }case CONVERSATION_IN_PROGRESS_REMOVE_FROM_LIST: {
      return {
        ...state,
        conversationsInProgress : state.conversationsInProgress.filter(conv => conv._id !== action.payload)
      }
    }case CONVERSATION_IN_PROGRESS_UPDATE: {
      return {
        ...state,
        conversationsInProgress : state.conversationsInProgress.map(conv => {
          if(conv._id === action.payload.conversationId){
            return {
              ...conv,
              status: action.payload.status,
              attendantName: action.payload.attendantName
            }
          }else {
            return conv
          }
        })
      }
    }
    default:
      return state;
  }
}
