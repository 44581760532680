import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";

import FilesService from "../../services/Files"
import { Types, listSuccess } from '../reducers/Files'

import { errorMessage } from "../actions/FeedbackMessage"

const getCompanyStore = state => state.company;

const listRequest = async (id) =>
    await FilesService.list(id)
        .then(files => files)
        .catch(error => { throw error })

const removeRequest = async (name) =>
    await FilesService.remove(name)
        .then(status => status)
        .catch(error => { throw error })

function* list({payload}) {
    try {
        const files = yield call(listRequest,payload)
        yield put(listSuccess(files))
    } catch (error) {
        yield put(errorMessage(error))
    }
}

function* remove({payload}) {
    try {
        const status = yield call(removeRequest,payload)
        if(status){
            const {company} = yield select(getCompanyStore)
            const files = yield call(listRequest,company._id)
            yield put(listSuccess(files))
        }
    } catch (error) {
        yield put(errorMessage(error))
    }
}

export function* listSaga() {
    yield takeLatest(Types.LIST, list)
}

export function* removeSaga() {
    yield takeLatest(Types.REMOVE, remove)
}

export default function* rootSaga() {
    yield all([
        fork(listSaga),
        fork(removeSaga)
    ]);
}
