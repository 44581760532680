import {
  CONVERSATION_TOTAL_MESSAGES,
  CONVERSATION_TOTAL_UNDERSTAND,
  CONVERSATION_TOP_INTENTS,
  CONVERSATION_TOP_ENTITIES,
  CONVERSATION_MESSAGES,
  CONVERSATION_IN_PROGRESS,
  CONVERSATION_MESSAGES_SUCCESS,
  CONVERSATION_IN_PROGRESS_REMOVE_FROM_LIST,
  CONVERSATION_IN_PROGRESS_UPDATE
} from "../../constants/ActionTypes";

export const countMessagesByCompanyId = (filters) => {
  return {
    type: CONVERSATION_TOTAL_MESSAGES,
    payload: filters
  };
};

export const countUnderstandByCompanyId = (filters) => {
  return {
    type: CONVERSATION_TOTAL_UNDERSTAND,
    payload: filters
  };
};

export const findTopIntentsByCompanyId = (filters) => {
  return {
    type: CONVERSATION_TOP_INTENTS,
    payload: filters
  };
};

export const findTopEntitiesByCompanyId = (filters) => {
  return {
    type: CONVERSATION_TOP_ENTITIES,
    payload: filters
  };
};

export const findMessages = (filters) => {
  return {
    type: CONVERSATION_MESSAGES,
    payload: filters
  }
}

export const findConversationInProgressByCompanyId = (filters) => {
  return {
    type: CONVERSATION_IN_PROGRESS,
    payload: filters
  };
};

export const findMessagesSuccess = (messages) => {
  return {
    type: CONVERSATION_MESSAGES_SUCCESS,
    payload: messages
  }
}

export const removeFromList = (conversationId) => {
  return {
      type: CONVERSATION_IN_PROGRESS_REMOVE_FROM_LIST,
      payload: conversationId
  }
}

export const updateConversationInProgress = (message) => {
  return {
      type: CONVERSATION_IN_PROGRESS_UPDATE,
      payload: message
  }
}
