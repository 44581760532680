export const Types = {
    SAVE_STEP:"SAVE_STEP"
}

const INIT_STATE = {
    step:0
};

export default (state = INIT_STATE, action) => {
    if(action.type === Types.SAVE_STEP){
        return {
            ...state,
            step: action.payload
        }
    }else{
        return state;
    }
}

export const saveStep = (step) => {
    return {
        type: Types.SAVE_STEP,
        payload: step
    };
};
