import {
    LOADING_BUTTON,
    UNLOADING_BUTTON,
    SHOW_MODAL,
    HIDE_MODAL,
    BUTTON_LOADING,
    COMPONENT_LOADING,
    TOGGLE_MODAL
} from "../../constants/ActionTypes";

export const buttonLoading = (loading) => {
    return {
        type: BUTTON_LOADING,
        payload: loading
    };
};

export const componentLoading = (loading) => {
    return {
        type: COMPONENT_LOADING,
        payload: loading
    };
};

export const loadingButton = () => {
    return {
        type: LOADING_BUTTON
    };
};
export const unloadingButton = () => {
    return {
        type: UNLOADING_BUTTON
    };
};

export const showModal = (clear) => {
    return {
        type: SHOW_MODAL,
        payload: clear
    };
};

export const hideModal = () => {
    return {
        type: HIDE_MODAL
    };
};

//Novos componentes devem usar esse método
export const toggleModal = (status) => {
    return {
        type: TOGGLE_MODAL,
        payload: status
    }
}
