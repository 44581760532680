import React, {Component} from "react";
import {connect} from "react-redux";
import {Drawer, Layout} from "antd";

import SidebarContent from "./SidebarContent";
import {toggleCollapsedSideNav, updateWindowWidth} from "appRedux/actions/Setting";
import {
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

const {Sider} = Layout;
const COLLAPSED_SIDEBAR = "gx-collapsed-sidebar";

export class Sidebar extends Component {

  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedSideNav(!this.props.navCollapsed);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });
  }

  render() {
    const {themeType, navCollapsed, width, navStyle} = this.props;

    let drawerStyle = COLLAPSED_SIDEBAR;

    switch (navStyle) {
      case NAV_STYLE_FIXED:
        drawerStyle = "";
        break;
      case NAV_STYLE_MINI_SIDEBAR:
        drawerStyle = "gx-mini-sidebar";
        break;
      default:
          drawerStyle = COLLAPSED_SIDEBAR
        break;
    }

    if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) {
      drawerStyle = COLLAPSED_SIDEBAR
    }
    return (
      <Sider
        className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
        trigger={null}
        collapsed={(width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR)}
        theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
        collapsible>
        {width < TAB_SIZE ?
          <Drawer
            className={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
            placement="left"
            closable={false}
            onClose={this.onToggleCollapsedNav.bind(this)}
            visible={navCollapsed}>
            <SidebarContent/>
          </Drawer> :
          <SidebarContent/>
        }
      </Sider>)
  }
}

const mapStateToProps = ({settings}) => {
  const {themeType, navStyle, navCollapsed, width, locale} = settings;
  return {themeType, navStyle, navCollapsed, width, locale}
};
export default connect(mapStateToProps, {toggleCollapsedSideNav, updateWindowWidth})(Sidebar);
