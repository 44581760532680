const exists = (attr, property) => {
    try {
        let firstLevel = property.indexOf(".")
        let firstObject = property
        let intoSearch = false
        if(firstLevel !== -1) {
            firstObject = property.substring(0, firstLevel)
            intoSearch = true
        }
        if(attr && attr !== undefined && attr.hasOwnProperty(firstObject)) {
            return (intoSearch ? exists(attr[firstObject], property.substring(firstLevel+1, property.length)) : true)
        }
        return false

    }catch(err) {
        return false
    }
}

const removeUrlPreffix = field => {
    try {
        if ( typeof field === "string" && field !== null
        && field !== undefined && field !== ""
        && field.length > 0 ) {
            return field.substring((field.search("://") + (field.search("://") > 0 ? 3 : 0) ), field.length)
        }
    } catch (err) {
        return
    }
}

const getUrlPreffix = field => {
    try {
        if ( typeof field === "string" && field !== null
        && field !== undefined && field !== ""
        && field.length > 0 ) {
            return field.substring(0,(field.search("://") + (field.search("://") > 0 ? 3 : 0) ))
        }
    } catch (err) {
        return "http://"
    }
}

const getMapGraph = (start, end, objectMerge) => {

    let arr = new Map();
    let dt = new Date(start);
    while (dt <= end) {

        let dateFormated

        if (start.getFullYear() === end.getFullYear()) {
            dateFormated = new Date(dt).toLocaleDateString('en-GB').substring(0, 5)
        } else {
            dateFormated = new Date(dt).toLocaleDateString('en-GB')
        }

        arr.set(dateFormated , Object.assign({}, Object.assign({date: dateFormated }, objectMerge) ) );
        dt.setDate(dt.getDate() + 1);
    }

    return arr;
}

module.exports = {
    exists: exists,
    removeUrlPreffix: removeUrlPreffix,
    getUrlPreffix: getUrlPreffix,
    getMapGraph: getMapGraph
}
