import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'
const BASE_URL = process.env.REACT_APP_FILE_MANAGER_URL
const SENDER_TOKEN = process.env.REACT_APP_FILE_MANAGER_SENDER_TOKEN

class Files {

    static async list(id) {
        try {
            return await Api.get(`/public/${id}`, {path: BASE_URL, token:""})
        } catch (err) {
            throw handleError(err)
        }
    }

    static async remove(data) {
        try {
            const {companyId, name} = data
            return await Api.delete(`/public/${companyId}/${name}`,{path: BASE_URL, token: SENDER_TOKEN})
        } catch (err) {
            throw handleError(err)
        }
    }
}

export default Files
