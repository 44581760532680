import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import RoleService from "../../services/Role";

import { Types,
    listRolesSuccess
} from "../reducers/Role"

import { errorMessage } from "../actions/FeedbackMessage"

import { componentLoading, buttonLoading, toggleModal } from "../actions/loading";

const listRequest = async () => RoleService.list()
    .then(users => users)
    .catch(error => { throw error });

const createRequest = async (data) => RoleService.create(data)
    .then(role => role)
    .catch(error => { throw error });

const updateRequest = async (data) => RoleService.update(data)
    .then(role => role)
    .catch(error => { throw error });

const removeRequest = async (id) => RoleService.remove(id)
    .then(role => role)
    .catch(error => { throw error });

function* list() {
    try {
        yield put(componentLoading(true))

        const roles = yield call(listRequest)

        yield put(componentLoading(false))
        yield put(listRolesSuccess(roles))
    } catch (error) {
        yield put(componentLoading(false))
        yield put(errorMessage(error))
    }
}

function* create({payload}) {
    try {
        yield put(buttonLoading(true))

        yield call(createRequest, payload)

        yield put(buttonLoading(false))
        yield put(toggleModal(false))

        const roles = yield call(listRequest)
        yield put(listRolesSuccess(roles))
    } catch (error) {
        yield put(buttonLoading(false))
        yield put(toggleModal(false))

        yield put(errorMessage(error))
    }
}

function* update({payload}) {
    try {
        yield put(buttonLoading(true))

        yield call(updateRequest, payload)

        yield put(buttonLoading(false))
        yield put(toggleModal(false))

        const roles = yield call(listRequest)
        yield put(listRolesSuccess(roles))
    } catch (error) {
        yield put(buttonLoading(false))
        yield put(toggleModal(false))

        yield put(errorMessage(error))
    }
}

function* remove({payload}) {
    try {
        yield put(buttonLoading(true))

        yield call(removeRequest, payload)

        yield put(buttonLoading(false))
        yield put(toggleModal(false))

        const roles = yield call(listRequest)
        yield put(listRolesSuccess(roles))
    } catch (error) {
        yield put(buttonLoading(false))
        yield put(toggleModal(false))

        yield put(errorMessage(error))
    }
}

export function* listSaga() {
    yield takeLatest(Types.LIST_ROLES, list);
}

export function* createSaga() {
    yield takeLatest(Types.CREATE_ROLE, create);
}

export function* updateSaga() {
    yield takeLatest(Types.UPDATE_ROLE, update);
}

export function* deleteSaga() {
    yield takeLatest(Types.DELETE_ROLE, remove);
}

export default function* rootSaga() {
    yield all([
        fork(listSaga),
        fork(createSaga),
        fork(updateSaga),
        fork(deleteSaga)
    ]);
}
