import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class User {
    static list() {
        return new Promise((resolve, reject) => {
            return Api.get(`/users`).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            return Api.post(`/users`,data).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static remove(id) {
        return new Promise((resolve, reject) => {
            return Api.delete(`/users/${id}`).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static update(user) {
        return new Promise((resolve, reject) => {
            return Api.put(`/users/${user._id}`, user).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }
}

export default User
