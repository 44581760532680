import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import ErrorLogService from "../../services/Logs";

import { componentLoading } from "../actions/loading";

import {
    Types,
    errorLogListSuccess,
    actionLogListSuccess
} from "../reducers/Logs";

import { errorMessage } from "../actions/FeedbackMessage";

const listErrorLogRequest = async payload =>
    await ErrorLogService.list(payload)
        .then(errors => errors)
        .catch(error => { throw error });

const listActionLogRequest = async payload =>
    await ErrorLogService.listActions(payload)
        .then(actions => actions)
        .catch(error => { throw error });


function* listErrorLog({ payload }) {
    try {
        yield put(componentLoading(true))
        const errors = yield call(listErrorLogRequest, payload)
        yield put(errorLogListSuccess(errors))
        yield put(componentLoading(false))
    } catch (error) {
        yield put(componentLoading(false))
        yield put(errorMessage("Erro ao exibir o console de erros"))
    }
}

function* listActionLog({ payload }) {
    try {
        yield put(componentLoading(true))
        const actions = yield call(listActionLogRequest, payload)
        yield put(actionLogListSuccess(actions))
        yield put(componentLoading(false))
    } catch (error) {
        yield put(componentLoading(false))
        yield put(errorMessage(error))
    }
}

export function* errorLogListSaga() {
    yield takeLatest(Types.ERROR_LOG_LIST, listErrorLog);
}

export function* actionLogListSaga() {
    yield takeLatest(Types.ACTION_LOG_LIST, listActionLog);
}

export default function* rootSaga() {
    yield all([
        fork(errorLogListSaga),
        fork(actionLogListSaga)
    ]);
}
