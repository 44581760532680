import enLang from "./entries/enUSLang";
import zhLang from "./entries/ZhLan";
import arLang from "./entries/arLang";
import itLang from "./entries/itLang";
import esLang from "./entries/esEsLang";
import frLang from "./entries/frLang";
import ptLang from "./entries/ptBrLang";
import {addLocaleData} from "react-intl";

const AppLocale = {
  pt: ptLang,
  en: enLang,
  zh: zhLang,
  ar: arLang,
  it: itLang,
  es: esLang,
  fr: frLang
};
addLocaleData(AppLocale.pt.data);
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.zh.data);
addLocaleData(AppLocale.ar.data);
addLocaleData(AppLocale.it.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.fr.data);

export default AppLocale;
