// Action Types
export const Types = {
    LIST_USER: 'LIST_USER',
    LIST_USER_SUCCESS: 'LIST_USER_SUCCESS',
    LIST_USER_BY_ID: 'LIST_USER_BY_ID',
    LIST_USER_BY_ID_SUCCESS: 'LIST_USER_BY_ID_SUCCESS',
    UPDATE_USER:'UPDATE_USER',
    UPDATE_USER_SUCCESS:'UPDATE_USER_SUCCESS',
    REMOVE_USER: 'REMOVE_USER',
    REMOVE_USER_SUCCESS: 'REMOVE_USER_SUCCESS',
    CREATE_USER: 'CREATE_USER',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS'
}

// Reducer
const INITIAL_STATE = {
    users: [],
    user: {}
}

export default function reducer(state = INITIAL_STATE, action) {
    if(action.type === Types.LIST_USER_SUCCESS){
        return {
            ...state,
            users: action.payload,
        }
    }else{
        return state;
    }
}

// Action Creators
export const listUser = () => {
    return {
        type: Types.LIST_USER
    }
}

export const listUserSuccess = (users) => {
    return {
        type: Types.LIST_USER_SUCCESS,
        payload: users
    }
}

export const createUser = (data) => {
    return {
        type: Types.CREATE_USER,
        payload: data
    }
}

export const removeUser = (id) => {
    return {
        type: Types.REMOVE_USER,
        payload: id
    }
}

export const updateUser = (user) => {
    return {
        type: Types.UPDATE_USER,
        payload: user
    }
}
