import {
    LOADING_BUTTON,
    UNLOADING_BUTTON,
    SHOW_MODAL,
    HIDE_MODAL,
    BUTTON_LOADING,
    COMPONENT_LOADING,
    TOGGLE_MODAL
} from "../../constants/ActionTypes";

const INIT_STATE = {
    buttonLoading: false,
    modalVisibility: false,
    clear:true,
    componentLoading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_MODAL: {
            return {
                ...state,
                modalVisibility: action.payload
            }
        }
        case BUTTON_LOADING:{
            return {
                ...state,
                buttonLoading: action.payload
            }
        }
        case COMPONENT_LOADING:{
            return {
                ...state,
                componentLoading: action.payload
            }
        }
        case LOADING_BUTTON: {
            return {
                ...state,
                buttonLoading: true
            }
        }
        case UNLOADING_BUTTON: {
            return {
                ...state,
                buttonLoading: false
            }
        }
        case SHOW_MODAL: {
            return {
                ...state,
                modalVisibility: true,
                clear: action.payload
            }
        }
        case HIDE_MODAL: {
            return {
                ...state,
                modalVisibility: false
            }
        }
        default:
            return state;
    }
}
