import { all, call, fork, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import BotService from "../../services/Bots";

import { Types,
    botList, botListSuccess,
    botModalVisible
} from "../reducers/Bot"

import { errorMessage, successMessage } from "../actions/FeedbackMessage"

import { componentLoading, buttonLoading } from "../actions/loading";

const getCompanyStore = state => state.company;

const listAllBotsRequest = async (id) =>
    await BotService.list(id)
        .then(bots => bots)
        .catch(error => { throw error });

const createBotRequest = async (payload) =>
    await BotService.create(payload)
        .then(bot => bot)
        .catch(error => { throw error });

const deleteBotRequest = async (payload) =>
    await BotService.remove(payload)
        .then(bot => bot)
        .catch(error => { throw error });

const updateBotRequest = async (payload) =>
    await BotService.update(payload)
        .then(bot => bot)
        .catch(error => { throw error });

function* listAllBotsWithCompanyId({payload}) {
    try {
        yield put(componentLoading(true))
        const bots = yield call(listAllBotsRequest,payload)
        yield put(componentLoading(false))
        yield put(botListSuccess(bots))
    } catch (error) {
        yield put(componentLoading(false))
        yield put(errorMessage(error))
    }
}

function* createBot({ payload }) {
    try {
        yield put(buttonLoading(true))
        yield call(createBotRequest, payload)
        yield put(buttonLoading(false))
        yield put(botModalVisible(false, true))
        yield put(successMessage("Bot criado com sucesso!"))
        try {
            const {company} = yield select(getCompanyStore)
            yield put(botList(company._id))
        } catch (err) {
            yield put(errorMessage(err))
        }
    } catch (error) {
        yield put(buttonLoading(false))
        yield put(errorMessage(error))
    }
}

function* deleteBot({ payload }) {
    yield put(buttonLoading(true))
    try {
        yield call(deleteBotRequest, payload)
        yield put(buttonLoading(false))
        yield put(botModalVisible(false, true))
        yield put(successMessage("Bot deletado com sucesso!"))
        try {
            const {company} = yield select(getCompanyStore)
            yield put(botList(company._id))
        } catch (err) {
            yield put(errorMessage(err))
        }
    } catch (error) {
        yield put(buttonLoading(false))
        yield put(botModalVisible(false, true))
        yield put(errorMessage(error))
    }
}

function* updateBot({ payload }) {
    yield put(buttonLoading(true))
    try {
        yield call(updateBotRequest, payload)
        yield put(buttonLoading(false))
        yield put(botModalVisible(false, true))
        yield put(successMessage("Bot atualizado com sucesso!"))
        try {
            const {company} = yield select(getCompanyStore)
            yield put(botList(company._id))
        } catch (err) {
            yield put(errorMessage(err))
        }
    } catch (error) {
        yield put(buttonLoading(false))
        yield put(errorMessage(error))
    }
}

export function* botListSaga() {
    yield takeLatest(Types.BOT_LIST, listAllBotsWithCompanyId);
}

export function* botCreateSaga() {
    yield takeEvery(Types.BOT_CREATE, createBot);
}

export function* botDeleteSaga() {
    yield takeEvery(Types.BOT_DELETE, deleteBot);
}

export function* botUpdateSaga() {
    yield takeEvery(Types.BOT_UPDATE, updateBot);
}

export default function* rootSaga() {
    yield all([
        fork(botListSaga),
        fork(botCreateSaga),
        fork(botDeleteSaga),
        fork(botUpdateSaga)
    ]);
}
