// Action Types
export const Types = {
    BOT_LIST: 'BOT_LIST',
    BOT_LIST_SUCCESS: 'BOT_LIST_SUCCESS',
    BOT_MODAL_VISIBLE: 'BOT_MODAL_VISIBLE',
    BOT_ACTION_ERROR: 'BOT_ACTION_ERROR',
    BOT_CREATE: 'BOT_CREATE',
    BOT_DELETE: 'BOT_DELETE',
    BOT_UPDATE: 'BOT_UPDATE',
    BOT_DETAIL_SHOW : "BOT_DETAIL_SHOW"
}

// Reducer
const INITIAL_STATE = {
    bot:{},
    bots: [],
    modalBotVisible: false,
    create: false
}

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.BOT_LIST_SUCCESS: {
            return {
                ...state,
                bots: action.payload
            }
        } case Types.BOT_MODAL_VISIBLE: {
            return {
                ...state,
                modalBotVisible: action.payload.visible,
                create: action.payload.create
            }
        } case Types.BOT_DETAIL_SHOW: {
            return {
              ...state,
              redirect: true,
              bot: action.payload
            };
        }case Types.BOT_ACTION_ERROR: {
            return {
                ...state
            }
        } default:
            return state;
    }
}

// Action Creators
export const botList = (id) => {
    return {
        type: Types.BOT_LIST,
        payload: id
    }
}

export const botListSuccess = (bots) => {
    return {
        type: Types.BOT_LIST_SUCCESS,
        payload: bots
    }
}

export const botActionError = () => {
    return {
        type: Types.BOT_ACTION_ERROR
    }
}

export const botCreate = (data) => {
    return {
        type: Types.BOT_CREATE,
        payload: data
    }

}

export const botModalVisible = (visible, create) => {
    return {
        type: Types.BOT_MODAL_VISIBLE,
        payload: {
            visible,
            create
        }
    }
}

export const botDetailShow = (bot) => {

    return {
        type: Types.BOT_DETAIL_SHOW,
        payload: bot
    }
  }

export const botDelete = (id) => {
    return {
        type: Types.BOT_DELETE,
        payload: id
    }
}

export const botUpdate = (data) => {
    return {
        type: Types.BOT_UPDATE,
        payload: data
    }
}
