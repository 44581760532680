// Action Types
export const Types = {
    LIST_ALL: 'LIST_ALL',
    LIST_ALL_SUCCESS: 'LIST_ALL_SUCCESS'
};

// Reducer
const INITIAL_STATE = {
    feedbacks:[]
}

export default function reducer(state = INITIAL_STATE, action) {
    if(action.type === Types.LIST_ALL_SUCCESS ){
        return {
            ...state,
            feedbacks: action.payload
        }
    }else{
        return state
    }
}

// Action Creators
export function listAll(filters) {
    return {
        type: Types.LIST_ALL,
        payload: filters
    }
}

export function listAllSuccess(feedbacks) {
    return {
        type: Types.LIST_ALL_SUCCESS,
        payload: feedbacks
    }
}
