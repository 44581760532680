import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Auth {
    static login(email,password) {
        return new Promise((resolve, reject) => {
            return Api.post(`/auth/`,{email, password}).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static refresh() {
        return new Promise((resolve, reject) => {
            return Api.get(`/auth`).then(res => {
                resolve(res)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }
}
export default Auth
