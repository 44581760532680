import React, {Component} from "react";
import {Layout} from "antd";
import ReactGA from "react-ga";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";

import Topbar from "../Topbar/index";
import App from "routes/index";
import {connect} from "react-redux";
import {
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";

const { Content } = Layout;

const GX_CONTAINER_WRAP = "gx-container-wrap"

export class MainApp extends Component {

  getContainerClass = (navStyle) => navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ? GX_CONTAINER_WRAP : ""

  getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL :
        return <HorizontalDefault/>;
      case NAV_STYLE_FIXED :
        return <Topbar/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Topbar/>;
      default :
        return null;
    }
  };

  getSidebar = (navStyle, width) => {
    if(this.props.storedStep < 4){
      return null
    }
    if (width < TAB_SIZE) {
      return <Sidebar/>;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED :
        return <Sidebar/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Sidebar/>;
      default :
        return null;
    }
  };

  componentDidMount() {
    ReactGA.initialize(process.env.REACT_APP_GA);
    ReactGA.pageview('/dashboard');
  }

  render() {
    const {match, width, navStyle, authUser} = this.props;

    return (
      <Layout className="gx-app-layout">
        {this.getSidebar(navStyle, width)}
        <Layout>
          {this.getNavStyles(navStyle)}
          <Content className={`gx-layout-content ${ this.getContainerClass(navStyle)} `}>
            <App match={match} user={authUser}/>
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({settings, tutorial, auth}) => {
  const {width, navStyle} = settings;
  const storedStep = tutorial.step
  const {authUser} = auth;
  return {width, navStyle, storedStep, authUser}
};

export default connect(mapStateToProps)(MainApp);
