// Action Types
export const Types = {
  HIDE_MESSAGE: 'HIDE_MESSAGE',
  INIT_URL:'INIT_URL',
  ON_HIDE_LOADER: 'ON_HIDE_LOADER',
  ON_SHOW_LOADER: 'ON_SHOW_LOADER',
  SHOW_LOADER: 'SHOW_LOADER',
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  SIGNIN_USER_SUCCESS: 'SIGNIN_USER_SUCCESS',
  SIGNOUT_USER_SUCCESS: 'SIGNOUT_USER_SUCCESS',
  SIGNUP_USER_SUCCESS: 'SIGNUP_USER_SUCCESS',
  VALIDATE_TOKEN:'VALIDATE_TOKEN',
  SIGNUP_USER:'SIGNUP_USER',
  SIGNIN_USER:'SIGNIN_USER',
  SIGNOUT_USER:'SIGNOUT_USER'
}

// Reducer
const INITIAL_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: {}
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SIGNUP_USER_SUCCESS:
    case Types.SIGNIN_USER_SUCCESS:{
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case Types.INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case Types.SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: {},
        initURL: '/',
        loader: false
      }
    }
    case Types.SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case Types.HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    case Types.ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case Types.SHOW_LOADER: {
      return {
        ...state,
        loader: action.payload
      }
    }
    case Types.ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    default:
      return state;
  }
}

// Action Creators
export const userSignUp = (user) => {
  return {
    type: Types.SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = (user) => {
  return {
    type: Types.SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: Types.SIGNOUT_USER
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: Types.SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: Types.SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};
export const userSignOutSuccess = () => {
  return {
    type: Types.SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: Types.SHOW_MESSAGE,
    payload: message
  };
};

export const setInitUrl = (url) => {
  return {
    type: Types.INIT_URL,
    payload: url
  };
};

export const showLoader = (show) => {
  return {
    type: Types.SHOW_LOADER,
    payload: show
  };
};

export const showAuthLoader = () => {
  return {
    type: Types.ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: Types.HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: Types.ON_HIDE_LOADER,
  };
};

export const validateToken = () => {
  return {
    type: Types.VALIDATE_TOKEN
  };
};
