import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import { NAV_STYLE_DEFAULT_HORIZONTAL } from "../../constants/ThemeSetting";


const SubMenu = Menu.SubMenu;

class HorizontalNav extends Component {

  getNavStyleSubMenuClass = (navStyle) => {
    if(navStyle === NAV_STYLE_DEFAULT_HORIZONTAL){
      return "gx-menu-horizontal gx-submenu-popup-curve";
    }else{
      return "gx-menu-horizontal";
    }
  };

  render() {
    const {pathname, navStyle} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (

      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal">

        <SubMenu className={this.getNavStyleSubMenuClass(navStyle)} key="main"
                 title={<IntlMessages id="sidebar.main"/>}>
          <Menu.Item key="sample">
            <Link to="/sample"><i className="icon icon-widgets"/>
              <IntlMessages id="sidebar.samplePage"/></Link>
          </Menu.Item>
          <Menu.Item key="company">
            <Link to="/company"><i className="icon icon-widgets"/>
              <IntlMessages id="sidebar.company"/></Link>
          </Menu.Item>
          <Menu.Item key="bots">
            <Link to="/bots"><i className="icon icon-widgets"/>
              <IntlMessages id="sidebar.bots"/></Link>
          </Menu.Item>
          <Menu.Item key="channel">
            <Link to="/channel"><i className="icon icon-widgets"/>
              <IntlMessages id="sidebar.channel"/></Link>
          </Menu.Item>
          <Menu.Item key="integration">
            <Link to="/integration"><i className="icon icon-testimonial"/>
              <IntlMessages id="sidebar.integration"/></Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  }
}

HorizontalNav.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {themeType, navStyle, pathname, locale} = settings;
  return {themeType, navStyle, pathname, locale}
};
export default connect(mapStateToProps)(HorizontalNav);

