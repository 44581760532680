import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import WebhookService from "../../services/Webhook";
import {WEBHOOK_LIST_ALL, WEBHOOK_CREATE, WEBHOOK_DELETE,WEBHOOK_UPDATE} from "../../constants/ActionTypes";

import {
    webhookListSuccess, webhookListError,
    webhookListAll, webhookModalVisible,
    webhookCreateSuccess, webhookCreateError,
    webhookDeleteSuccess, webhookDeleteError,
    webhookUpdateSuccess, webhookUpdateError,
    webhoookComponentVisible
} from "../actions/Webhook";

import {errorMessage, successMessage} from "../actions/FeedbackMessage";

const ERROR_TO_CREATE_ACTION = "Erro ao listar ações"

const getCompanyStore = state => state.company;

const listAllWebhookRequest = async (id) =>
  await WebhookService.listAllWebhooks(id)
    .then(actions => actions)
    .catch(error => error);

const createWebhookRequest = async (data) =>
    await WebhookService.createWebhook(data)
      .then(actions => actions)
      .catch(error => error);

const deleteWebhookRequest = async (id) =>
    await WebhookService.deleteWebhook(id)
    .then(actions => actions)
    .catch(error => error);

const updateWebhookRequest = async (data) =>
    await WebhookService.updateWebhook(data)
    .then(actions => actions)
    .catch(error => error);

function* listAllWebhook({payload}) {
    try {
        yield put(webhoookComponentVisible(true))
        const actions = yield call(listAllWebhookRequest,payload)
        yield put(webhoookComponentVisible(false))
        yield put(webhookListSuccess(actions))
    } catch (error) {
        yield put(webhoookComponentVisible(false))
        yield put(webhookListError());
    }
}

function* createWebhook({payload}) {
    try {
        const webhook = yield call(createWebhookRequest,payload)
        if(webhook){
            yield put(webhookCreateSuccess(webhook))
            yield put(webhookModalVisible(false))
            yield put(successMessage("Ação cadastrado com sucesso"))
            try {
                const {company} = yield select(getCompanyStore)
                yield put(webhookListAll(company._id))
            } catch (error) {
                yield put(errorMessage(ERROR_TO_CREATE_ACTION));
            }
        }else{
            yield put(errorMessage("Erro ao cadastrar ação"));
        }
    } catch (error) {
        yield put(webhookCreateError());
        yield put(errorMessage("Erro ao cadastrar ação"));
    }
}

function* updateWebhook({payload}) {
    try {
        const webhook = yield call(updateWebhookRequest,payload)
        if(webhook){
            yield put(webhookUpdateSuccess())
            yield put(webhookModalVisible(false))
            yield put(successMessage("Ação atualizado com sucesso"))
            try {
                const {company} = yield select(getCompanyStore)
                yield put(webhookListAll(company._id))
            } catch (error) {
                yield put(errorMessage(ERROR_TO_CREATE_ACTION));
            }
        }else{
            yield put(errorMessage("Erro ao atualizar ação"));
        }
    } catch (error) {
        yield put(webhookUpdateError());
        yield put(errorMessage("Erro ao atualizar ação"));
    }
}

function* deleteWebhook({payload}) {
    try {
        yield call(deleteWebhookRequest,payload)
        yield put(webhookDeleteSuccess())
        yield put(successMessage("Ação apagado com sucesso"))
        try {
            const {company} = yield select(getCompanyStore)
                yield put(webhookListAll(company._id))
        } catch (error) {
            yield put(errorMessage(ERROR_TO_CREATE_ACTION));
        }
    } catch (error) {
        yield put(webhookDeleteError());
        yield put(errorMessage("Erro ao deletar ação"));
    }
}

export function* webhookListAllSaga() {
    yield takeLatest(WEBHOOK_LIST_ALL, listAllWebhook);
}

export function* createWebhookSaga() {
    yield takeLatest(WEBHOOK_CREATE, createWebhook);
}

export function* deleteWebhookSaga() {
    yield takeLatest(WEBHOOK_DELETE, deleteWebhook);
}

export function* updateeWebhookSaga() {
    yield takeLatest(WEBHOOK_UPDATE, updateWebhook);
}

export default function* rootSaga() {
    yield all([
        fork(webhookListAllSaga),
        fork(createWebhookSaga),
        fork(deleteWebhookSaga),
        fork(updateeWebhookSaga)
    ]);
}
