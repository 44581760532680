import io from "socket.io-client"

const socket = io.connect(`${process.env.REACT_APP_API_URL}orchestrator/chat`);

const joinInGroup = (payload) =>{
    const localToken = JSON.parse(localStorage.getItem("token"))
    payload.authorization = `Bearer ${localToken}`
    socket.emit('join', payload)
}

const sendMessage = (payload) => {
    const localToken = JSON.parse(localStorage.getItem("token"))
    payload.authorization = `Bearer ${localToken}`
    socket.emit('analist-message', payload)
}

const startIntervention = (payload) => {
    const localToken = JSON.parse(localStorage.getItem("token"))
    payload.authorization = `Bearer ${localToken}`
    socket.emit('start-intervention', payload)
}

const listenMessage = (cb) => {
    socket.on('received message', (response) => cb(response))
}

const listenErros  = (cb) => {
    socket.on('orchestrationError', (error) => cb(true))
}

export {joinInGroup, sendMessage, listenMessage, listenErros, startIntervention, socket }
