const ObjectUtil = require('./ObjectUtil');

const ERROR_DEFAULT = "Ocorreu um erro no servidor"

const handleError = (error, altMessage) => {
    try {
        let returnError = (altMessage?altMessage:ERROR_DEFAULT)
        if(ObjectUtil.exists(error,"response.data.errors")&& error.response.data.errors.length > 0){
            if(error.response.data.errors[0].hasOwnProperty("message")) {
                returnError = error.response.data.errors[0].message
            }
        }
        return returnError

    }catch(err) {
        return altMessage
    }
}

module.exports = {
    handleError:handleError
}
