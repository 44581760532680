import Api from "./Api";
import { exists, removeUrlPreffix } from "../util/ObjectUtil";
import { handleError } from "../util/ApiErrorUtil";

export default class Channel {
  static makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static normalizeData(payload) {
    let channel;

    channel = payload.payload.data;
    channel.companyId = payload.payload.company._id;
    channel.type = payload.payload.channel.nome;

    if (payload.payload.data.customization.botImage) {
      let urlImagem = payload.payload.data.customization.botImage;
      urlImagem = removeUrlPreffix(urlImagem);

      channel.customization.botImage =
        payload.payload.data.prefixUrlImagem + urlImagem;
    }

    //Convertendo a mensagem enviada para o formato padrão do orquestrador
    if (payload.payload.data.customization.startMessage) {
      let startMessage = [];
      let textMessage = {
        responseType: "text",
        text: payload.payload.data.customization.startMessage
      };
      startMessage.push(textMessage);
      if (
        payload.payload.data.customization.startMessageOptions &&
        payload.payload.data.customization.startMessageOptions.length > 0
      ) {
        let options = payload.payload.data.customization.startMessageOptions.map(
          option => {
            return {
              label: option,
              value: option
            };
          }
        );
        let optionMessage = {
          responseType: "option",
          options
        };
        startMessage.push(optionMessage);
      }
      channel.customization.startMessage = startMessage;
    }

    if (payload.payload.data.settings.embeddedUrls
      && payload.payload.data.settings.embeddedUrls.length < 2) {
      let urlEmbarcar = payload.payload.data.settings.embeddedUrls;
      urlEmbarcar = removeUrlPreffix(urlEmbarcar);
      channel.settings.embeddedUrls =
        payload.payload.data.prefixUrlEmbarcar + urlEmbarcar;
    }

    channel.conversationId = this.makeid(7);

    return channel;
  }

  static create(payload) {
    let channel;

    try {
      channel = this.normalizeData(payload);
    } catch (e) {
      console.log("ERRO NORMALIZEDATA", e);
    }
    return new Promise((resolve, reject) => {
      return Api.post("/channel", channel)
        .then(res => {
          const sendBack = {
            tokenIdTelegram: channel.tokenIdTelegram,
            res: res
          };
          Api.post("/telegram/", sendBack).then(res => {});
          Api.post("/whatsapp/zenvia", res).then(res => {});
          if (res && exists(res, "_id")) {
            resolve(res);
          } else {
            reject(res);
          }
        })
        .catch(err => {
          reject(handleError(err, "Ocorreu um erro ao criar um canal"));
        });
    });
  }

  static update(payload) {
    let channel;

    try {
      channel = this.normalizeData(payload);
    } catch (e) {
      console.log("ERRO NORMALIZEDATA", e);
    }
    return new Promise((resolve, reject) => {
      return Api.put(`/channel/${channel._id}`, channel)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(handleError(err, "Ocorreu um erro ao atualizar um canal"));
        });
    });
  }

  static index() {
    return new Promise((resolve, reject) => {
      return Api.get(`/channel`)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(handleError(err));
        });
    });
  }

  static delete(payload) {
    return new Promise((resolve, reject) => {
      return Api.delete(`/channel/${payload.channel._id}`)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(handleError(err, "Ocorreu um erro ao deletar um canal"));
        });
    });
  }
}
