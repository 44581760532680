import { all, fork, put, takeLatest, take } from "redux-saga/effects";
import {
  CHANNEL_GET_ALL,
  WEBHOOK_LIST_ALL,
  COMPANY_LIST,
  ERROR_MESSAGE,
  CONVERSATION_TOTAL_MESSAGES,
  CONVERSATION_TOTAL_UNDERSTAND,
  CONVERSATION_TOP_INTENTS,
  CONVERSATION_TOP_ENTITIES,
  CONVERSATION_MESSAGES
} from "../../constants/ActionTypes";

import { userSignOut } from "../reducers/Auth";
import { errorMessage } from "../actions/FeedbackMessage"
import { Types } from "../reducers/Bot"

function* handleError() {
    try{
      const error = yield take([ERROR_MESSAGE]);
      if(error.payload === "Token inválido." || error.payload === "Desculpe, sua sessão expirou."){
        yield put(userSignOut())
      }
    }catch (err){
        yield put(errorMessage(err.message || "Ocorreu um erro inesperado."))
    }
}

export function* handleErrorMessage() {
  yield takeLatest([
    CHANNEL_GET_ALL,
    Types.BOT_LIST,
    WEBHOOK_LIST_ALL,
    COMPANY_LIST,
    CONVERSATION_TOTAL_MESSAGES,
    CONVERSATION_TOTAL_UNDERSTAND,
    CONVERSATION_TOP_INTENTS,
    CONVERSATION_TOP_ENTITIES,
    CONVERSATION_MESSAGES
  ], handleError);
}

export default function* rootSaga() {
  yield all([fork(handleErrorMessage)]);
}
