import { extractMessages } from "../../util/Message"

// Action Types
export const Types = {
    CHAT_LOADING: 'CHAT_LOADING',
    MESSAGE_RECEIVED: 'MESSAGE_RECEIVED',
    FOLLOW_CONVERSATION:'FOLLOW_CONVERSATION',
    CONVERSATION_MESSAGES_BY_ID:'CONVERSATION_MESSAGES_BY_ID',
    UNDERSTANDING_MESSAGES_BY_ID:'UNDERSTANDING_MESSAGES_BY_ID',
    CONVERSATION_MESSAGES_BY_ID_SUCCESS:'CONVERSATION_MESSAGES_BY_ID_SUCCESS',
    UNDERSTANDING_MESSAGES_BY_ID_SUCCESS:'UNDERSTANDING_MESSAGES_BY_ID_SUCCESS',
    START_INTERVENTION: 'START_INTERVENTION',
    START_INTERVENTION_SUCCESS: 'START_INTERVENTION_SUCCESS',
    REFRESH_CURRENT_CONTACT:'REFRESH_CURRENT_CONTACT'
}

// Reducer
const INIT_STATE = {
    chatLoading: false,
    detailedConversation: {
        context:{},
        conversation:[]
    },
    understandingConversation: {
        context:{},
        conversation:[]
    },
    refreshCurrentContact:false
}

export default function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case Types.CHAT_LOADING:
            return {
                ...state,
                chatLoading: action.payload
            }
        //Lidando com as mensagens recebidas através do socket
        case Types.MESSAGE_RECEIVED:
            if(action.payload.conversationId === state.detailedConversation._id){
                const conversation  = {
                    ...state.detailedConversation,
                    conversation : state.detailedConversation.conversation.concat(extractMessages(action.payload))
                }
                return {
                    ...state,
                    detailedConversation : conversation
                }
            }else{
                return {...state}
            }
        case Types.CONVERSATION_MESSAGES_BY_ID_SUCCESS:
            return {
                ...state,
                detailedConversation : action.payload
            }
        case Types.UNDERSTANDING_MESSAGES_BY_ID_SUCCESS:
            return {
                ...state,
                understandingConversation : action.payload
            }
        case Types.REFRESH_CURRENT_CONTACT:
            return {
                ...state,
                refreshCurrentContact : action.payload
            }
        default:
            return state
    }
}

// Action Creators
export const chatLoading = (state) => {
    return {
        type: Types.CHAT_LOADING,
        payload: state
    }
}

export const messageReceived = (message) => {
    return {
        type: Types.MESSAGE_RECEIVED,
        payload: message
    }
}

export const findById = (id) => {
    return {
        type: Types.CONVERSATION_MESSAGES_BY_ID,
        payload: id
    }
}
export const findByIdSuccess = (conversation) => {
    return {
        type: Types.CONVERSATION_MESSAGES_BY_ID_SUCCESS,
        payload: conversation
    }
}
export const findUnderstandingById = (id) => {
    return {
        type: Types.UNDERSTANDING_MESSAGES_BY_ID,
        payload: id
    }
}

export const findUnderstandingByIdSuccess = (conversation) => {
    return {
        type: Types.UNDERSTANDING_MESSAGES_BY_ID_SUCCESS,
        payload: conversation
    }
}

export const startIntervention = (message) => {
    return {
        type: Types.START_INTERVENTION,
        payload: message
    }
}

export const refreshCurrentContact = (state) => {
    return {
        type: Types.REFRESH_CURRENT_CONTACT,
        payload: state
    }
}
