// Action Types
export const Types = {
  COMPANY_CREATE:'COMPANY_CREATE',
  COMPANY_CREATE_SUCESS: 'COMPANY_CREATE_SUCESS',
  COMPANY_CREATE_ERROR: 'COMPANY_CREATE_ERROR',
  COMPANY_UPDATE:'COMPANY_UPDATE',
  COMPANY_UPDATE_SUCESS: 'COMPANY_UPDATE_SUCESS',
  COMPANY_UPDATE_ERROR: 'COMPANY_UPDATE_ERROR',
  COMPANY_LIST:'COMPANY_LIST',
  COMPANY_WORD:'COMPANY_WORD',
  COMPANY_LIST_SUCESS: 'COMPANY_LIST_SUCESS',
  COMPANY_WORD_SUCESS: 'COMPANY_WORD_SUCESS',
  COMPANY_DELETE:'COMPANY_DELETE',
  COMPANY_DELETE_SUCESS: 'COMPANY_DELETE_SUCESS',
  COMPANY_DELETE_ERROR: 'COMPANY_DELETE_ERROR',
  COMPANY_UPDATE_TUTORIAL_STEP:'COMPANY_UPDATE_TUTORIAL_STEP',
  FIND_BY_ID:'FIND_BY_ID',
  FIND_BY_ID_SUCCESS:'FIND_BY_ID_SUCCESS',
  FIND_BY_ID_ERROR:'FIND_BY_ID_ERROR',
}

// Reducer
const INITIAL_STATE = {
  company:{},
  word: null,
  companies:[]
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.COMPANY_CREATE_SUCESS: {
      return {
        ...state,
        sucessMessage:"Empresa registrada com sucesso"
      }
    }
    case Types.COMPANY_LIST_ERROR:
    case Types.COMPANY_CREATE_ERROR: {
      return {
        ...state,
        errorMessage:action.payload
      }
    }case Types.COMPANY_DELETE_SUCESS: {
      return {
        ...state,
        sucessMessage:"Empresa removida com sucesso"
      }
    }case Types.COMPANY_DELETE_ERROR: {
      return {
        ...state,
        errorMessage:"Ocorreu um erro ao remover a empresa"
      }
    }case Types.COMPANY_LIST_SUCESS: {
      return {
        ...state,
        companies:action.payload
      }
    }
    case Types.COMPANY_WORD_SUCESS: {
  
      return {
        ...state,
        word: action.payload
      }
    }

    case Types.FIND_BY_ID_SUCCESS: {
      return {
        ...state,
        company:action.payload
      }
    }
    default:
      return state;
  } 
}

// Action Creators
export const createCompany = (company) => {
  return {
      type: Types.COMPANY_CREATE,
      payload: company
  };
};

export const updateCompany = (company) => {
  return {
      type: Types.COMPANY_UPDATE,
      payload: company
  };
};

export const deleteCompany = (id) => {
  return {
    type: Types.COMPANY_DELETE,
    payload: id
  };
};

export const companiesList = (res) => {
  return {
    type: Types.COMPANY_LIST
  }
}


export const wordList = (res) => {
  return {
    type: Types.COMPANY_WORD
  }
}


export const updateTutorialSteps = (company) => {
  return {
    type: Types.COMPANY_UPDATE_TUTORIAL_STEP,
    payload: company
  };
};

export const findById = (id) => {
  return {
    type: Types.FIND_BY_ID,
    payload: id
  }
}

export const findByIdSuccess = (company) => {
  return {
    type: Types.FIND_BY_ID_SUCCESS,
    payload: company
  }
}
