// Action Types
export const Types = {
    ORCHESTRATION_ERROR: 'ORCHESTRATION_ERROR'
}

// Reducer
const INIT_STATE = {
    errors: false
}

export default function reducer(state = INIT_STATE, action) {
    switch (action.type) {
        case Types.ORCHESTRATION_ERROR:
            return {
                ...state,
                errors: action.payload
            }
        default:
            return state
    }
}

// Action Creators
export const setOrchestrationError = (status) => {
    return {
        type: Types.ORCHESTRATION_ERROR,
        payload: status
    }
}
