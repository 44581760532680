import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import {Types, companiesList, findByIdSuccess} from "../reducers/Company"
import {saveStep} from "../reducers/Tutorial"

import CompanyService from "../../services/Company";

import { componentLoading, buttonLoading, toggleModal } from "../actions/loading";
import { errorMessage } from "../actions/FeedbackMessage";

const removeRequest = async (id) =>
  await CompanyService.remove(id)
    .then(res => res)
    .catch(err => {throw err})

const createRequest = async (company) =>
  await CompanyService.create(company)
    .then(res => res)
    .catch(err => {throw err})

const updateRequest = async (company) =>
  await CompanyService.update(company)
    .then(res => res)
    .catch(err => {throw err})

const listRequest = async () => CompanyService.list()
  .then((companies) => companies)
  .catch((err) => {throw err})

  const wordRequest = async () => CompanyService.wordCloudByCompany()
  .then((words) => words)
  .catch((err) => {throw err})

const findByIdRequest = async (id) =>
  await CompanyService.listCompanieById(id)
    .then((companie) => companie)
    .catch((err) => {throw err})

function* remove({payload}) {
  try {
    const response = yield call(removeRequest, payload)
    yield put({ type: Types.COMPANY_DELETE_SUCESS, payload: response })
    yield put(companiesList())
  } catch (error) {
    yield put(errorMessage(error))
    yield put({ type: Types.COMPANY_DELETE_ERROR, payload: error })
  }
}

function* create({payload}) {
  try {
    yield put(buttonLoading(true))
    yield call(createRequest, payload)
    yield put(companiesList())
    yield put(buttonLoading(false))
    yield put(toggleModal(false))
  } catch (error) {
    yield put(errorMessage(error))
    yield put({ type: Types.COMPANY_CREATE_ERROR, payload: error })
    yield put(buttonLoading(false))
    yield put(toggleModal(false))
  }
}

function* update({payload}) {
  try {
    yield put(buttonLoading(true))
    yield call(updateRequest, payload)
    yield put(buttonLoading(false))
    yield put(toggleModal(false))
    yield put(companiesList())
  } catch (error) {
    yield put(errorMessage(error))
    yield put(buttonLoading(false))
    yield put(toggleModal(false))
  }
}

function* list() {
  try {
    const companies = yield call(listRequest)
    yield put({ type: Types.COMPANY_LIST_SUCESS, payload: companies })
  } catch (error) {
    yield put({ type: Types.COMPANY_LIST_ERROR, payload: error })
  }
}

function* wordcloudFNC() {
  try {
    yield put(componentLoading(true))
    const words = yield call(wordRequest)
    yield put({ type: Types.COMPANY_WORD_SUCESS, payload: words })
    yield put(componentLoading(false))

  } catch (error) {
    yield put({ type: Types.COMPANY_LIST_ERROR, payload: error })
  }
}


function* updateCompanieStepTutorial({payload}) {
  try {
    const company = yield call(updateRequest, payload)
    yield put({ type: Types.COMPANY_UPDATE_SUCESS, payload: company })
    yield put(saveStep(company.stepTutorial))
  } catch (error) {
    yield put({ type: Types.COMPANY_UPDATE_ERROR, payload: error })
  }
}

function* findById({payload}) {
  try {
    const company = yield call(findByIdRequest, payload)
    yield put(findByIdSuccess(company))
  } catch (error) {
    yield put({ type: Types.FIND_BY_ID_ERROR, payload: error })
  }
}

export function* createCompany() {
  yield takeLatest(Types.COMPANY_CREATE, create);
}

export function* updateCompany() {
  yield takeLatest(Types.COMPANY_UPDATE, update);
}

export function* deleteCompany() {
  yield takeLatest(Types.COMPANY_DELETE, remove);
}

export function* listCompanies() {
  yield takeLatest(Types.COMPANY_LIST, list);
}
export function* wordList() {
  yield takeLatest(Types.COMPANY_WORD, wordcloudFNC);
}
export function* updateCompanieStep() {
  yield takeLatest(Types.COMPANY_UPDATE_TUTORIAL_STEP, updateCompanieStepTutorial);
}

export function* findByIdSaga() {
  yield takeLatest(Types.FIND_BY_ID, findById);
}

export default function* rootSaga() {
  yield all([
    fork(listCompanies),
    fork(wordList),
    fork(deleteCompany),
    fork(createCompany),
    fork(updateCompany),
    fork(updateCompanieStep),
    fork(findByIdSaga)
  ]);
}
