import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Avatar, Popover} from "antd";
import {userSignOut} from "../../appRedux/reducers/Auth";

import adm from '../../assets/images/adm.png'
import dev from '../../assets/images/dev.png'
import att from '../../assets/images/att.png'
import ger from '../../assets/images/ger.png'
import pent from '../../assets/images/pentagon.png'

class UserInfo extends Component {

  getImage(user){    
    switch (user.role) {
      case 'ADM':
        return adm;
      case 'GER':
        return ger;
      case 'ATT':
        return att;
      case 'DEV':
        return dev;
      default:
          return pent;
    }
  }
  
  render() {
    const {authUser} = this.props
    const source = this.getImage(authUser)
    const userMenuOptions = (
      <ul id="profileMenu" className="gx-user-popover">
        <li id="logoutButton" onClick={() => this.props.userSignOut()}>Logout
        </li>
      </ul>
    );

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
               trigger="click">
        <Avatar id="avatarIcon" size={35} src={source}
                className="gx-avatar gx-pointer" alt=""/>
      </Popover>
    )
    
  }
}

const mapStateToProps = store => {
  const authUser = store.auth.authUser
  return {authUser}
  };

export default connect(mapStateToProps, {userSignOut})(UserInfo);

