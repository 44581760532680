import Api from './Api'

class Webhook {

    static listAllWebhooks() {
        return new Promise((resolve, reject) => {
            return Api.get(`/actions`).then(webhooks => {
                resolve(webhooks)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    static createWebhook(data) {
        return new Promise((resolve, reject) => {
            return Api.post(`/actions`,data).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    static updateWebhook(data) {
        return new Promise((resolve, reject) => {
            return Api.put(`/actions/${data._id}`,data).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }

    static deleteWebhook(payload) {
        return new Promise((resolve, reject) => {
            return Api.delete(`/actions/${payload}`).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

export default Webhook
