import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";

import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  showLoader,
  Types
} from "../reducers/Auth"

import {
  findByIdSuccess as findCompanyByIdSuccess
} from "../reducers/Company"

import {saveStep} from "../../appRedux/reducers/Tutorial";
import Company from "../../services/Company"
import Auth from "../../services/Auth";

const uid = { user: {udi: 'vUAqPRNWgYdS2DyJNAC1lV5KpJF2'} }

const listCompanyByIdRequest = (id) => Company.listById(id)
  .then(company => company)
  .catch(err =>{throw err})

const validateTokenRequest = () => Auth.refresh()
  .then(status => status)
  .catch(err =>{throw err})

const signInUserWithEmailPasswordRequest = (login,password) => Auth.login(login,password)
  .then(user => user)
  .catch(err =>{throw err})

function* createUserWithEmailPassword({payload}) {
  try {
    const signUpUser = yield uid
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user', signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({payload}) {
  try {
    const response = yield call(signInUserWithEmailPasswordRequest,payload.email, payload.password)
    if(response){
      localStorage.setItem('token', JSON.stringify(response.token));

      const company = yield call(listCompanyByIdRequest,response.user.companyId);

      //Set company and first time tutorial
      yield put(findCompanyByIdSuccess(company));
      yield put(saveStep(company.stepTutorial))

      //Set auth user and redirect to first page
      yield put(userSignInSuccess(response.user));

    } else {
      yield put(showAuthMessage("Usuário não encontrado."));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
      localStorage.removeItem('token');
      yield put(userSignOutSuccess());
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* validateToken() {
  try {
    yield put(showLoader(true))
    const user = yield call(validateTokenRequest);
    const company = yield call(listCompanyByIdRequest,user.companyId);

    //Set company and first time tutorial
    yield put(findCompanyByIdSuccess(company));
    yield put(saveStep(company.stepTutorial))

    //Set auth user and redirect to first page
    yield put(userSignInSuccess(user));
  } catch (error) {
    yield put(userSignOutSuccess());
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(Types.SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(Types.SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(Types.SIGNOUT_USER, signOut);
}
export function* validateUserToken() {
  yield takeLatest(Types.VALIDATE_TOKEN, validateToken);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(createUserAccount),
    fork(validateUserToken),
    fork(signOutUser)]);
}
