import {
    WEBHOOK_LIST_ALL,
    WEBHOOK_LIST_SUCCESS,
    WEBHOOK_LIST_ERROR,
    WEBHOOK_CREATE,
    WEBHOOK_CREATE_SUCCESS,
    WEBHOOK_CREATE_ERROR,
    WEBHOOK_DELETE_ERROR,
    WEBHOOK_DELETE_SUCCESS,
    WEBHOOK_DELETE,
    WEBHOOK_MODAL_VISIBLE,
    WEBHOOK_UPDATE,
    WEBHOOK_UPDATE_ERROR,
    WEBHOOK_UPDATE_SUCCESS,
    WEBHOOK_COMPONENT_VISIBLE
} from "../../constants/ActionTypes";

export const webhookListAll = (id) => {
    return {
        type: WEBHOOK_LIST_ALL,
        payload: id
    };
};

export const webhookListSuccess = (actions) => {
    return {
        type: WEBHOOK_LIST_SUCCESS,
        payload: actions
    };
};

export const webhookListError = () => {
    return {
        type: WEBHOOK_LIST_ERROR
    };
};

export const webhookCreate = (data) => {
    return {
        type: WEBHOOK_CREATE,
        payload: data
    };
};

export const webhookCreateSuccess = (webhook) => {
    return {
        type: WEBHOOK_CREATE_SUCCESS,
        payload: webhook
    };
};

export const webhookCreateError = () => {
    return {
        type: WEBHOOK_CREATE_ERROR
    };
};

export const webhookModalVisible = (visible,create) => {
    return {
        type: WEBHOOK_MODAL_VISIBLE,
        payload: {
            visible,
            create
        }
    };
};

export const webhookDeleteSuccess = (actions) => {
    return {
        type: WEBHOOK_DELETE_SUCCESS,
        payload: actions
    };
};

export const webhookDeleteError = () => {
    return {
        type: WEBHOOK_DELETE_ERROR
    };
};

export const webhookDelete = (id) => {
    return {
        type: WEBHOOK_DELETE,
        payload: id
    };
};

export const webhookUpdateSuccess = () => {
    return {
        type: WEBHOOK_UPDATE_SUCCESS
    };
};

export const webhookUpdateError = () => {
    return {
        type: WEBHOOK_UPDATE_ERROR
    };
};

export const webhookUpdate = (webhook) => {
    return {
        type: WEBHOOK_UPDATE,
        payload: webhook
    };
};

export const webhoookComponentVisible = (loading) => {
    return {
        type: WEBHOOK_COMPONENT_VISIBLE,
        payload: loading
    };
  };
