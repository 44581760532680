import React from "react";
import { Icon } from "antd";

const BugSvg = () => (
    <svg data-name="Material Expand" id="Material_Expand" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.188,52.173A2,2,0,0,0,5,54v8H9V55.3l6.992-3.108a17.968,17.968,0,0,0,32.016,0L55,55.3V62h4V54a2,2,0,0,0-1.188-1.827l-8.39-3.729a17.858,17.858,0,0,0,.511-3.115l7.879-3.5A2,2,0,0,0,59,40V32H55v6.7l-5,2.223V30.3l7.812-3.472A2,2,0,0,0,59,25V17H55v6.7l-5.325,2.367A6.006,6.006,0,0,0,44,22H42V18a7.941,7.941,0,0,0-.99-3.847l3.239-2.591A2,2,0,0,0,45,10V5H41V9.039l-2.747,2.2A7.939,7.939,0,0,0,34,10H30a7.939,7.939,0,0,0-4.253,1.237L23,9.039V5H19v5a2,2,0,0,0,.751,1.562l3.239,2.591A7.941,7.941,0,0,0,22,18v4H20a6.006,6.006,0,0,0-5.675,4.067L9,23.7V17H5v8a2,2,0,0,0,1.188,1.827L14,30.3V40.923L9,38.7V32H5v8a2,2,0,0,0,1.188,1.827l7.879,3.5a17.858,17.858,0,0,0,.511,3.115ZM18,44V34H30V57.84A14.009,14.009,0,0,1,18,44ZM34,57.84V34H46V44A14.009,14.009,0,0,1,34,57.84ZM26,18a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v4H26Zm-6,8H44a2,2,0,0,1,2,2v2H18V28A2,2,0,0,1,20,26Z"/>
    </svg>
)

const BugIcon = props => (
    <Icon component={BugSvg} {...props} style={{width:"20px"}} />
);

export default BugIcon
