import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import {
	CONVERSATION_IN_PROGRESS,
	CONVERSATION_IN_PROGRESS_SUCCESS,
	CONVERSATION_TOTAL_MESSAGES,
	CONVERSATION_TOTAL_UNDERSTAND,
	CONVERSATION_TOTAL_MESSAGES_SUCCESS,
	CONVERSATION_TOTAL_UNDERSTAND_SUCCESS,
	CONVERSATION_TOP_INTENTS,
	CONVERSATION_TOP_INTENTS_SUCCESS,
	CONVERSATION_TOP_ENTITIES_SUCCESS,
	CONVERSATION_TOP_ENTITIES,
	CONVERSATION_MESSAGES
} from "../../constants/ActionTypes";

import { findMessagesSuccess, updateConversationInProgress } from "../actions/Conversation"
import { chatLoading, Types, findByIdSuccess, findUnderstandingByIdSuccess, refreshCurrentContact } from "../reducers/Chat"

import { errorMessage } from "../actions/FeedbackMessage"

import ConversationService from "../../services/Conversation";

import { componentLoading, buttonLoading } from "../actions/loading";

const countByCompanyIdRequest = async (payload) => ConversationService.countAllByCompanyId(payload)
    .then((conversations) => conversations)
    .catch((err) => { throw err })

const findMessagesRequest = async (payload) =>
	await ConversationService.findMessages(payload)
		.then((conversations) => conversations)
		.catch((err) => {
			throw err
		})

const findMessagesByIdRequest = async (payload) =>
	await ConversationService.findMessagesById(payload)
		.then((conversations) => conversations)
		.catch((err) => {
			throw err
		})

const findTopIntentsByCompanyIdRequest = async (payload) =>
	await ConversationService.findTopIntentsByCompanyId(payload)
		.then((topIntents) => topIntents)
		.catch((err) => {
			throw err
		})

const findTopEntitiesByCompanyIdRequest = async (payload) =>
	await ConversationService.findTopEntitiesByCompanyId(payload)
		.then((topEntities) => topEntities)
		.catch((err) => {
			throw err
		})

const findConversationsInProgressByCompanyIdRequest = async (payload) =>
	await ConversationService.findConversationsInProgressByCompanyId(payload)
		.then((topEntities) => topEntities)
		.catch((err) => {
			throw err
		})

const startInterventionRequest = async (payload) => ConversationService.startIntervention(payload)
	.then(data => data)
	.catch(error => { throw error });

function* countMessagesByCompanyIdService({ payload }) {
	try {
		const payloadFromUser = {
			filters: {
				...payload.filters,
				from:"user"
			}
		}

    	const payloadFromBot = {
      		filters: {
        		...payload.filters,
        		from:"bot"
      		}
		}

		const results = yield all([
			call(countByCompanyIdRequest, payloadFromUser),
			call(countByCompanyIdRequest, payloadFromBot)
		])

		//First result -> totalReceivedMessages
		//Second result -> totalSendedMessages
		const totalMessages = results[0] + results[1]

		const payloadRetorno = {
			totalMessages,
			totalReceivedMessages : results[0],
			totalSendedMessages : results[1]
		}

    	yield put({ type: CONVERSATION_TOTAL_MESSAGES_SUCCESS, payload: payloadRetorno })
  	} catch (error) {
    	yield put(errorMessage(error))
  	}
}

function* countUnderstandByCompanyIdService({ payload }) {
	try {
		const payloadFromBot = {
			companyId:payload.companyId,
			filters: {
				...payload.filters,
				from:"bot"
			}
		}

		const payloadMasterUnderstanding = {
			companyId:payload.companyId,
			filters: {
				...payload.filters,
				from:"bot",
				understandingMaster: false
			}
		}

		const results = yield all([
			call(countByCompanyIdRequest, payloadFromBot),
			call(countByCompanyIdRequest, payloadMasterUnderstanding)
		])

		//First result -> totalMessagesFromBot
		//Second result -> totalNotUnderstandMessages
		const totalUnderstandMessages = parseInt(results[0]) - parseInt(results[1])

		const payloadRetorno = {
			totalMessagesFromBot: parseInt(results[0]),
			totalNotUnderstandMessages: parseInt(results[1]),
			totalUnderstandMessages
		}

		yield put({ type: CONVERSATION_TOTAL_UNDERSTAND_SUCCESS, payload: payloadRetorno })

	} catch (error) {
		yield put(errorMessage(error))
	}
}

function* findTopIntentsByCompanyIdService({ payload }) {
	try {
		const topIntents = yield call(findTopIntentsByCompanyIdRequest, payload)
		yield put({ type: CONVERSATION_TOP_INTENTS_SUCCESS, payload: topIntents })

  } catch (error) {
    yield put(errorMessage(error))
  }
}

function* findTopEntitiesByCompanyIdService({ payload }) {
	try {
		const topEntities = yield call(findTopEntitiesByCompanyIdRequest, payload)
		yield put({ type: CONVERSATION_TOP_ENTITIES_SUCCESS, payload: topEntities })

  } catch (error) {
    yield put(errorMessage(error))
  }
}

function* findMessages({ payload }) {
  try {
    yield put(componentLoading(true))
    const messages = yield call(findMessagesRequest, payload)
    yield put(findMessagesSuccess(messages))
    yield put(componentLoading(false))
  } catch (error) {
    yield put(componentLoading(false))
    yield put(errorMessage(error))
  }
}

function* findMessagesById({ payload }) {
	try {
		const message = yield call(findMessagesByIdRequest, payload)
		yield put(findByIdSuccess(message))
		yield put(chatLoading(false))
	} catch (error) {
		yield put(errorMessage(("Ocorreu um erro ao pesquisar as mensagens")))
		yield put(chatLoading(false))
	}
}
function* findUnderstandingMessagesById({ payload }) {
	try {
		yield put(chatLoading(true))
		const message = yield call(findMessagesByIdRequest, payload)
		yield put(chatLoading(false))
		yield put(findUnderstandingByIdSuccess(message))
	} catch (error) {
		yield put(errorMessage(("Ocorreu um erro ao pesquisar as mensagens")))
		yield put(chatLoading(false))
	}
}

function* findConversationsInProgressByCompanyIdService({ payload }) {
	try {
		const conversations = yield call(findConversationsInProgressByCompanyIdRequest, payload)
		yield put({ type: CONVERSATION_IN_PROGRESS_SUCCESS, payload: conversations })

	} catch (error) {
		yield put(errorMessage(("Ocorreu um erro ao pesquisar as conversas em andamento")))
	}
}

function* startIntervention({ payload }) {
	try {
		yield put(buttonLoading(true))
		const data = yield call(startInterventionRequest, payload)

		yield put(updateConversationInProgress(data))
		yield put(refreshCurrentContact(true))
		yield put(buttonLoading(false))
	} catch (error) {
		yield put(buttonLoading(false))
		yield put(errorMessage(error))
	}
}

export function* countMessagesByCompanyId() {
	yield takeLatest(CONVERSATION_TOTAL_MESSAGES, countMessagesByCompanyIdService);
}

export function* countUnderstandByCompanyId() {
	yield takeLatest(CONVERSATION_TOTAL_UNDERSTAND, countUnderstandByCompanyIdService);
}

export function* findTopIntentsByCompanyId() {
	yield takeLatest(CONVERSATION_TOP_INTENTS, findTopIntentsByCompanyIdService);
}

export function* findTopEntitiesByCompanyId() {
	yield takeLatest(CONVERSATION_TOP_ENTITIES, findTopEntitiesByCompanyIdService);
}

export function* findMessagesEvent() {
	yield takeLatest(CONVERSATION_MESSAGES, findMessages);
}

export function* findConversationsInProgressByCompanyId() {
	yield takeLatest(CONVERSATION_IN_PROGRESS, findConversationsInProgressByCompanyIdService);
}

export function* findMessagesByIdEvent() {
	yield takeLatest(Types.CONVERSATION_MESSAGES_BY_ID, findMessagesById);
}

export function* findUnderstandingMessagesByIdEvent() {
	yield takeLatest(Types.UNDERSTANDING_MESSAGES_BY_ID, findUnderstandingMessagesById);
}

export function* startInterventionSaga() {
	yield takeLatest(Types.START_INTERVENTION, startIntervention);
}

export default function* rootSaga() {
	yield all([
		fork(countMessagesByCompanyId),
		fork(countUnderstandByCompanyId),
		fork(findTopIntentsByCompanyId),
		fork(findTopEntitiesByCompanyId),
		fork(findMessagesEvent),
		fork(findMessagesByIdEvent),
		fork(findUnderstandingMessagesByIdEvent),
  		fork(findConversationsInProgressByCompanyId),
  		fork(startInterventionSaga)
	]);
}
