import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";

import {
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

import {
  PAGE_ACTIONS,
  PAGE_BOTS,
  PAGE_CHANNELS,
  PAGE_DASHBOARD,
  PAGE_FILES,
  PAGE_USERS,
  PAGE_COMPANIES,
  PAGE_CHAT,
  PAGE_INTERVENTION,
  PAGE_ROLES
} from "../../constants/ActionTypes";

import IntlMessages from "../../util/IntlMessages";
import {IsAuthorized} from "../../util/AuthUtil";
const MenuItemGroup = Menu.ItemGroup;
class SidebarContent extends Component {

  render() {
    const { themeType, pathname, authUser } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
    <Auxiliary>

      <SidebarLogo />
      <div className="gx-sidebar-content">
        <p></p>
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
            {IsAuthorized(PAGE_DASHBOARD, authUser.permissions) &&
              <Menu.Item key="dashboard">
                  <Link to="/dashboard"><Icon type="dashboard" />
                  <IntlMessages id="sidebar.dashboard"/></Link>
              </Menu.Item>
            }
            {IsAuthorized(PAGE_BOTS, authUser.permissions) &&
              <Menu.Item key="bots">
                <Link to="/bots"><Icon id="botsButton" type="robot" />
                  <IntlMessages id="sidebar.bots" /></Link>
              </Menu.Item>
            }
            {IsAuthorized(PAGE_CHANNELS, authUser.permissions) &&
              <Menu.Item key="channel">
                <Link to="/channel"><Icon id="channelButton" type="mobile" />
                  <IntlMessages id="sidebar.channel" /></Link>
              </Menu.Item>
            }
            {IsAuthorized(PAGE_ACTIONS, authUser.permissions) &&
              <Menu.Item key="actions">
                <Link to="/actions"><Icon id="actionsButton" type="api" />
                  <IntlMessages id="sidebar.actions" /></Link>
              </Menu.Item>
            }
            {IsAuthorized(PAGE_FILES, authUser.permissions) &&
              <Menu.Item key="upload">
                <Link to="/files"><Icon type="cloud-upload" />
                  <IntlMessages id="sidebar.files" /></Link>
              </Menu.Item>
            }
            {IsAuthorized(PAGE_USERS, authUser.permissions) &&
              <Menu.Item key="users">
                <Link to="/users"><Icon type="usergroup-add" />
                  <IntlMessages id="sidebar.users" /></Link>
              </Menu.Item>
            }
            {IsAuthorized(PAGE_ROLES, authUser.permissions) &&
              <Menu.Item key="roles">
                <Link to="/roles"><Icon type="control" />
                  <IntlMessages id="sidebar.roles" /></Link>
              </Menu.Item>
            }
            {IsAuthorized(PAGE_COMPANIES, authUser.permissions) &&
              <Menu.Item key="companies">
                <Link to="/companies"><Icon type="bank" />
                  <IntlMessages id="sidebar.companies" /></Link>
              </Menu.Item>
            } 
            {IsAuthorized(PAGE_COMPANIES, authUser.permissions) &&
              <Menu.Item key="word">
                <Link to="/words"><Icon type="cloud"  />
                  <IntlMessages id="sidebar.word" /></Link>
              </Menu.Item>
            }
            <MenuItemGroup key="monitoramento" className="gx-menu-group" title={<IntlMessages id="sidebar.monitoring"/>}>
              {IsAuthorized(PAGE_INTERVENTION, authUser.permissions) &&
                <Menu.Item key="intervention">
                  <Link to="/intervention"><Icon type="eye" />
                    <IntlMessages id="sidebar.dashboard.intervention" />
                  </Link>
                </Menu.Item>
              }
              {IsAuthorized(PAGE_CHAT, authUser.permissions) &&
                <Menu.Item key="chat">
                  <Link to="/chat"><Icon type="message" />
                    <IntlMessages id="sidebar.dashboard.chat" />
                  </Link>
                </Menu.Item>
              }
            </MenuItemGroup>
            </Menu>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const {authUser} = auth
  return { navStyle, themeType, locale, pathname, authUser }
};
export default connect(mapStateToProps)(SidebarContent);
