import {
    WEBHOOK_LIST_SUCCESS,
    WEBHOOK_LIST_ERROR,
    WEBHOOK_DELETE_ERROR,
    WEBHOOK_DELETE_SUCCESS,
    WEBHOOK_MODAL_VISIBLE,
    WEBHOOK_COMPONENT_VISIBLE
} from "../../constants/ActionTypes";

const INIT_STATE = {
    webhooks: [],
    modalVisible: false,
    createModal: true,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case WEBHOOK_LIST_SUCCESS: {
            return {
                ...state,
                webhooks: action.payload
            }
        }
        case WEBHOOK_DELETE_ERROR:
        case WEBHOOK_DELETE_SUCCESS:
        case WEBHOOK_LIST_ERROR: {
            return {
                ...state
            }
        }
        case WEBHOOK_MODAL_VISIBLE: {
            return {
                ...state,
                modalVisible: action.payload.visible,
                createModal: action.payload.create
            }
        } case WEBHOOK_COMPONENT_VISIBLE: {
            return {
              ...state,
              loading: action.payload
            }
        }
        default:
            return state;
    }
}
