import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Logs {

    static list(data) {
        return new Promise((resolve, reject) => {
            const {filters} = data
            return Api.post(`/logs/errors`, filters).then(errors => {
                resolve(errors)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static listActions(data) {
        return new Promise((resolve, reject) => {
           const { filters } = data
            return Api.post(`/logs/action/all`, filters).then(actions => {
                resolve(actions)
            }).catch((err) => {
                reject(handleError(err,"Erro ao exibir a lista de ações"))
            })
        })
    }
}

export default Logs
