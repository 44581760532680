import {
    ERROR_MESSAGE,
    INFO_MESSAGE,
    SUCCESS_MESSAGE,
    WARNING_MESSAGE
} from "../../constants/ActionTypes";

import {message} from "antd"
message.config({
    maxCount: 2
});

const INIT_STATE = {
    message: ""
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ERROR_MESSAGE: {
            message.error(action.payload)
            return {
                ...state
            }
        }
        case INFO_MESSAGE: {
            message.info(action.payload)
            return {
                ...state
            }
        }
        case SUCCESS_MESSAGE: {
            message.success(action.payload)
            return {
                ...state
            }
        }
        case WARNING_MESSAGE: {
            message.warning(action.payload)
            return {
                ...state
            }
        }
        default:
            return state;
    }
}
